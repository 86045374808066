import { PROTECTED_PATHS, PUBLIC_PATHS } from './constants';
import { AppRoute } from './types';
import WithSuspense from '../components/WithSuspense';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Dashboard = WithSuspense(lazy(() => import('../pages/Dashboard')));
const Welfare = WithSuspense(lazy(() => import('../pages/Welfare')));
const Profile = WithSuspense(lazy(() => import('../pages/ChampionProfile')));
const CreateGuarantor = WithSuspense(
  lazy(() => import('../pages/ChampionProfile/ChampionInfoSection/GuarantorInfoSection/CreateNewGuarantor/index'))
);
const UpdateGuarantor = WithSuspense(lazy(() => import('../pages/ChampionProfile/ChampionInfoSection/GuarantorInfoSection/ActiveGuarantor/UpdateGuarantorDetails/index')));
const FieldOps = WithSuspense(lazy(() => import('../pages/FieldOps')));
const FieldAgents = WithSuspense(lazy(() => import('../pages/FieldOps/FieldAgents')));
const Notifications = WithSuspense(lazy(() => import('../pages/Notifications')));
const FieldAgentsEmergencies = WithSuspense(lazy(() => import('../pages/FieldOps/FieldAgentsEmergencies')));
const FieldOpsIncidents = WithSuspense(lazy(() => import('pages/FieldOps/FieldOpsIncidents')));
const FlagDetails = WithSuspense(lazy(() => import('pages/FlagDetails')));
const FlaggedChampions = WithSuspense(lazy(() => import('pages/FlaggedChampions')));
const FlagHistory = WithSuspense(lazy(() => import('pages/FlagHistory')));
const Administration = WithSuspense(lazy(() => import('pages/Administration')));
const ContactCenter = WithSuspense(lazy(() => import('pages/ContactCenter')));
const Contracts = WithSuspense(lazy(() => import('pages/Contracts')));
const ContractsReassign = WithSuspense(lazy(() => import('pages/Contracts/ContractsReassign')));

const {
  DASHBOARD,
  WELFARE,
  PROFILE,
  CREATE_GUARANTOR,
  UPDATE_GUARANTOR,
  FIELD_OPS,
  FIELD_AGENTS,
  FIELD_AGENTS_EMERGENCIES,
  FIELD_OPS_INCIDENTS,
  NOTIFICATION,
  FLAG_DETAILS,
  FLAG_HISTORY,
  FLAGGED_CHAMPIONS,
  ADMINISTRATION,
  CONTACT_CENTER,
  CONTRACTS,
  VEHICLEREASSIGN
} = PROTECTED_PATHS;

export const PROTECTED_ROUTES: AppRoute[] = [
  { path: DASHBOARD, element: <Dashboard /> },
  { path: WELFARE, element: <Welfare /> },
  { path: PROFILE, element: <Profile /> },
  { path: CREATE_GUARANTOR, element: <CreateGuarantor /> },
  { path: UPDATE_GUARANTOR, element: <UpdateGuarantor /> },
  { path: FIELD_OPS, element: <FieldOps /> },
  { path: FIELD_AGENTS, element: <FieldAgents /> },
  { path: FIELD_AGENTS_EMERGENCIES, element: <FieldAgentsEmergencies /> },
  { path: FIELD_OPS_INCIDENTS, element: <FieldOpsIncidents /> },
  { path: NOTIFICATION, element: <Notifications /> },
  { path: FLAG_DETAILS, element: <FlagDetails /> },
  { path: FLAGGED_CHAMPIONS, element: <FlaggedChampions /> },
  { path: FLAG_HISTORY, element: <FlagHistory /> },
  { path: ADMINISTRATION, element: <Administration /> },
  { path: CONTRACTS, element: <Contracts /> },
  { path: VEHICLEREASSIGN, element: <ContractsReassign /> },

  { path: '/', element: <Dashboard /> },
  { path: CONTACT_CENTER, element: <ContactCenter /> },
  // this enables you not to access the public routes when logged in

  ...Object.values(PUBLIC_PATHS).map((route) => {
    return {
      path: route,
      element: <Navigate to={DASHBOARD} />
    };
  }),
  { path: '*', element: <div>Page not found</div> }
];
