import React from 'react';
import { NavbarContainer, LogoContainer, MenuItem, MenuItemContainer, ProfileIconContainer } from './styles';
import { FaSearch } from 'react-icons/fa';
import { decrypt_user0bj } from 'utils/auth-utils';
import storage from 'utils/storage';
import { userKey } from 'utils/constants';
import { UserInterface } from 'models/auth';
import { NavList } from './NavList';
import { NavLink, useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from 'routes/constants';
import Logout from '../Logout';

const Navbar = () => {
  const userObject: UserInterface = decrypt_user0bj(userKey ? storage.get(userKey) : '');
  const history = useNavigate();

  const [showLogout, setShowLogout] = React.useState(false);

  const handleShowLogout = () => {
    return showLogout ? setShowLogout(false) : setShowLogout(true);
  };

  return (
    <NavbarContainer>
      <LogoContainer onClick={() => history(PROTECTED_PATHS.DASHBOARD)}>
        <h2 className="root-header">DOMS</h2>
      </LogoContainer>
      <MenuItemContainer>
        {NavList.map((list, index) => (  
          <MenuItem key={index}>
            <NavLink to={list.path} className={(route) => (route.isActive ? 'active-menu' : '')}>
              {list.name}
            </NavLink>
          </MenuItem>
        ))}
        <MenuItem>
          <FaSearch color="#3E3E3E" />
        </MenuItem>
        <MenuItem>
          <ProfileIconContainer src={userObject?.photo} alt="Profile" onClick={() => handleShowLogout()} />
          <Logout showLogoutModal={showLogout} />
        </MenuItem>
      </MenuItemContainer>
    </NavbarContainer>
  );
};

export default Navbar;
