import styled from 'styled-components';
import { device } from '../../styles';

const NavbarContainer = styled.nav`
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 20% auto;
    grid-column-gap: 50px;
    z-index: 500;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    grid-template-columns: 20% auto;
    grid-column-gap: 5px;
  }
`;

const LogoContainer = styled.div`
  background: #3e3e3e;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  cursor: pointer;

  .root-header {
    text-align: left;
    font-size: 15px;
    font-family: 'TT Bold';
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .root-header:hover {
    color: #fcdd18;
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const MenuItem = styled.div`
  border-right: 1px solid #eeeeee;
  width: fit-content;
  height: 100%;
  padding: 0 25px;
  align-items: center;
  display: flex;
  position: relative;

  .active-menu {
    font-family: 'TT Bold';
    font-size: 12px;
    letter-spacing: 0px;
    color: #3e3e3e;
    text-transform: uppercase;
    opacity: 1;
  }

  &:first-child {
    border-left: 1px solid #eeeeee;
  }
  a {
    font-family: 'TT Bold';
    font-size: 12px;
    letter-spacing: 0px;
    color: #3e3e3e;
    text-transform: uppercase;
    opacity: 0.3;

    &:hover {
      opacity: 1 !important;
    }
  }
`;

const ProfileIconContainer = styled.img`
  width: 27px;
  height: 27px;
  border-radius: 50px;
  object-fit: cover;
  cursor: pointer;
`;

export { NavbarContainer, LogoContainer, MenuItemContainer, MenuItem, ProfileIconContainer };
