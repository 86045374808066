import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { PROTECTED_ROUTES } from '../routes/protected-routes';
import { Box } from '@chakra-ui/react';
import ScrollToTop from 'components/ScrollToTop';
import Navbar from "components/Navbar/index";

const AppWrapper = () => {
  const routes = useRoutes(PROTECTED_ROUTES);
  return routes;
};

const Authenticated = () => {

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Box height="100%" pt="60px">
        <AppWrapper />
      </Box>
    </Router>
  );
};

export default Authenticated; 