import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@chakra-ui/react';
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <Spinner color="#fcdd18" size={'xl'} speed="0.5s" />
    </LoaderContainer>
  );
};

export default Loader;
