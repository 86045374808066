export const ButtonStyles = {
  // style object for base or default style
  baseStyle: {
    outline: "none",
    _focus: { boxShadow: "none" },
    textTransform: 'uppercase',
    fontFamily: 'TT Bold',
    fontSize: '12px',
    borderRadius: '3px',

  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: () => ({
      bg: "primary",
      padding: "1rem",
      minWidth: '150px',
      fontSize: '12px',
      _hover: {
        bg: '#3E3E3E',
        color: 'white',
        boxShadow: "md",
        _disabled: {
          backgroundColor: '#cccccc',
          color: '#666666'
        },
      },
      _disabled: {
        backgroundColor: '#cccccc',
        color: '#666666'
      }

    }),
    secondary: () => ({
      bg: "#EEEEEE",
      padding: "1rem",
      color: '#3E3E3E',
      fontSize: '12px',
      minWidth: '150px',
      _hover: {
        bg: '#E5EBF5',
        boxShadow: "md",
      },
    }),
    secondaryOutline: () => ({
      bg: "transparent",
      border: "1px solid #EEEEEE",
      borderRadius: '3px',
      fontSize: '12px',
      fontWeight: 'normal',
      transition: "all 200ms ease",
      _hover: {
        boxShadow: "md",
        transform: "scale(1.02)",
      },
      _focus: {
        outline: "none",
      },
    }),
  },
  // default values for `size` and `variant`
  defaultProps: {
    variant: "primary",
  },
};
