export const PUBLIC_PATHS = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password'
};

export const PROTECTED_PATHS = {
  DASHBOARD: '/dashboard',
  WELFARE: '/welfare',
  PROFILE: '/welfare/:champion_id',
  CREATE_GUARANTOR: '/welfare/:champion_id/create-guarantor',
  UPDATE_GUARANTOR: '/welfare/:champion_id/update-guarantor',
  FIELD_OPS: '/field-ops',
  FIELD_OPS_INCIDENTS: '/field-ops/incidents',
  FIELD_AGENTS: '/field-ops/agents',
  FIELD_AGENTS_EMERGENCIES: '/field-ops/agents/:agent_id',
  ADMINISTRATION: '/administration',
  RE_VERIFICATION: '/reverification',
  NOTIFICATION: '/notification',
  APPLICATION_HUB: '/application-hub',
  FLAG_DETAILS: '/flagged-champions/champion/:champion_id',
  FLAGGED_CHAMPIONS: '/flagged-champions',
  FLAG_HISTORY: '/flagged-champions/history/:champion_id',
  CONTACT_CENTER: '/contact-center',
  CONTRACTS: '/contracts',
  VEHICLEREASSIGN: '/contracts/vehiclereassign/:champion_id'
};
