import { ChampionContractsObj } from 'models/welfare';
import React, { useContext, useState, createContext } from 'react';

const pageNumber = 1;
const query = '';
const tabIndex = -1;
const showWelfareTable = false;

const initialState = {
  //eslint-disable-next-line
  page: pageNumber,
  //eslint-disable-next-line
  storePage: (value: number) => {},
  startDate: query,
  //eslint-disable-next-line
  storeStartDate: (value: string) => {},
  endDate: query,
  //eslint-disable-next-line
  storeEndDate: (value: string) => {},
  tableStartDateQuery: query,
  //eslint-disable-next-line
  storeTableStartDateQuery: (value: string) => {},
  tableEndDateQuery: query,
  //eslint-disable-next-line
  storeTableEndDateQuery: (value: string) => {},
  nameSearchQuery: query,
  //eslint-disable-next-line
  storeNameSearchQuery: (value: string) => {},
  tableIndex: tabIndex,
  //eslint-disable-next-line
  storeTableIndex: (value: number) => { },
  showWelfareTableModal: { show: showWelfareTable, rowIndex: tabIndex },
  //eslint-disable-next-line
  storeShowWelfareTableModal: (value: boolean, index: number) => { },
  championInfo: {} as ChampionContractsObj,
  //eslint-disable-next-line
  storeChampionInfo: (value: ChampionContractsObj) => { },
};

const ContractContext = createContext(initialState);
export const useContractContext = () => useContext(ContractContext);
export const ContractProvider = ContractContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const ContractProviderContainer: React.FC<Props> = ({ children }) => {
  const [page, setPage] = React.useState(initialState.page);
  const storePage = (value: number) => {
    setPage(value);
  };
  const [startDate, setStartDate] = React.useState(initialState.startDate);
  const storeStartDate = (value: string) => {
    setStartDate(value);
  };

  const [endDate, setEndDate] = React.useState(initialState.endDate);
  const storeEndDate = (value: string) => {
    setEndDate(value);
  };

  const [tableIndex, setTableIndex] = useState(initialState.tableIndex);
  const storeTableIndex = (value: number) => {
    setTableIndex(value);
  };


  const [championInfo, setChampionInfo] = useState(initialState.championInfo);
  const storeChampionInfo = (value: ChampionContractsObj) => {
    setChampionInfo(value);
  };

  const [showWelfareTableModal, setShowWelfareTableModal] = useState(initialState.showWelfareTableModal);
  const storeShowWelfareTableModal = (value: boolean, index: number) => {
    setShowWelfareTableModal({ show: value, rowIndex: index });
  };

  const [tableStartDateQuery, setTableStartDateQuery] = React.useState(initialState.tableStartDateQuery);
  const storeTableStartDateQuery = (value: string) => {
    setTableStartDateQuery(value);
  };

  const [tableEndDateQuery, setTableEndDateQuery] = React.useState(initialState.tableEndDateQuery);
  const storeTableEndDateQuery = (value: string) => {
    setTableEndDateQuery(value);
  };

  const [nameSearchQuery, setNameSearchQuery] = React.useState(initialState.nameSearchQuery);
  const storeNameSearchQuery = (value: string) => {
    setNameSearchQuery(value);
  };

  return (
    <ContractProvider
      value={{
        page,
        storePage,
        startDate,
        storeStartDate,
        endDate,
        storeEndDate,
        tableStartDateQuery,
        storeTableStartDateQuery,
        tableEndDateQuery,
        storeTableEndDateQuery,
        nameSearchQuery,
        storeNameSearchQuery,
        tableIndex,
        storeTableIndex,
        showWelfareTableModal,
        storeShowWelfareTableModal,
        championInfo,
        storeChampionInfo,
      }}
    >
      {children}
    </ContractProvider>
  );
};

export default ContractContext;
