import { PUBLIC_PATHS, PROTECTED_PATHS } from './constants';
import { AppRoute } from './types';
import WithSuspense from '../components/WithSuspense'  
import { lazy } from 'react'
import { Navigate } from "react-router-dom";

const Login = WithSuspense(lazy(() => import('../pages/Login'))); 
const ForgetPassword = WithSuspense(lazy(() => import('../pages/ForgetPassword'))); 
const ResetPassword = WithSuspense(lazy(() => import('../pages/ResetPassword'))); 


const { LOGIN, FORGOT_PASSWORD, RESET_PASSWORD } = PUBLIC_PATHS


export const PUBLIC_ROUTES: AppRoute[] = [    
  { path: LOGIN, element: <Login /> },
  { path: "/", element: <Login /> },
  { path: FORGOT_PASSWORD, element: <ForgetPassword /> },
  { path: RESET_PASSWORD, element: <ResetPassword /> },
  // this enables you not to access the protecteds routes when logged out
  ...Object.values(PROTECTED_PATHS).map((route) => {
    return {
      path: route,
      element: <Navigate to={LOGIN} />
    }
  }),
  { path: "*", element: <div>Page not found</div> },   
]   


