import { Suspense } from 'react';
import Loader from '../components/CustomLoader';

const WithSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default WithSuspense;   
