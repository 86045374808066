import React from 'react';
import styled from 'styled-components';
import { logoutFunction } from './logout';

const LogoutContainer = styled.div`
  width: 80px;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 45px;

  .text {
    text-align: center;
    font-family: 'TT Regular';
    font-size: 14px;
    letter-spacing: 0px;
    color: #808080;
    opacity: 1;
  }
`;

const Logout = (props) => {
  const { showLogoutModal } = props;

  return (
    <>
      {showLogoutModal && (
        <LogoutContainer onClick={() => logoutFunction()}>
          <h4 className="text">Logout</h4>
        </LogoutContainer>
      )}
    </>
  );
};

export default Logout;
