import React, { useState, useContext } from 'react';

const tabIndex = -1;
const pageNumber = 1;
const query = '';
const show = false;

const initialState = {
  tableIndex: tabIndex,
  //eslint-disable-next-line
  storeTableIndex: (value: number) => {},
  showFieldOpsTableModal: { show: show, rowIndex: tabIndex },
  //eslint-disable-next-line
  storeShowFieldOpsTableModal: (value: boolean, index: number) => {},
  page: pageNumber,
  //eslint-disable-next-line
  storePage: (value: number) => {},
  categorySearchQuery: query,
  //eslint-disable-next-line
  storeCategorySearchQuery: (value: string) => {},
  statusSearchQuery: query,
  //eslint-disable-next-line
  storeStatusSearchQuery: (value: string) => {},
  startDate: query,
  //eslint-disable-next-line
  storeStartDate: (value: string) => {},
  endDate: query,
  //eslint-disable-next-line
  storeEndDate: (value: string) => {},
  tableStartDateQuery: query,
  //eslint-disable-next-line
  storeTableStartDateQuery: (value: string) => {},
  tableEndDateQuery: query,
  //eslint-disable-next-line
  storeTableEndDateQuery: (value: string) => {},
  incidentIdQuery: query,
  //eslint-disable-next-line
  storeIncidentIdQuery: (value: string) => {},
  championNameQuery: query,
  //eslint-disable-next-line
  storeChampionNameQuery: (value: string) => {}
};

const FieldAgentsContext = React.createContext(initialState);

export const FieldAgentsProvider = FieldAgentsContext.Provider;
export const useFieldAgentsContext = () => useContext(FieldAgentsContext);
type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const FieldAgentsProviderContainer: React.FC<Props> = ({ children }) => {
  const [tableIndex, setTableIndex] = useState(initialState.tableIndex);
  const storeTableIndex = (value: number) => {
    setTableIndex(value);
  };

  const [showFieldOpsTableModal, setShowFieldOpsTableModal] = useState(initialState.showFieldOpsTableModal);
  const storeShowFieldOpsTableModal = (value: boolean, index: number) => {
    setShowFieldOpsTableModal({ show: value, rowIndex: index });
  };

  const [page, setPage] = useState(initialState.page);
  const storePage = (value: number) => {
    setPage(value);
  };

  const [categorySearchQuery, setCategorySearchQuery] = React.useState(initialState.categorySearchQuery);
  const storeCategorySearchQuery = (value: string) => {
    setCategorySearchQuery(value);
  };

  const [statusSearchQuery, setStatusSearchQuery] = React.useState(initialState.categorySearchQuery);
  const storeStatusSearchQuery = (value: string) => {
    setStatusSearchQuery(value);
  };

  const [startDate, setStartDate] = React.useState(initialState.startDate);
  const storeStartDate = (value: string) => {
    setStartDate(value);
  };

  const [endDate, setEndDate] = React.useState(initialState.endDate);
  const storeEndDate = (value: string) => {
    setEndDate(value);
  };

  const [tableStartDateQuery, setTableStartDateQuery] = React.useState(initialState.tableStartDateQuery);
  const storeTableStartDateQuery = (value: string) => {
    setTableStartDateQuery(value);
  };

  const [tableEndDateQuery, setTableEndDateQuery] = React.useState(initialState.tableEndDateQuery);
  const storeTableEndDateQuery = (value: string) => {
    setTableEndDateQuery(value);
  };

  const [incidentIdQuery, setIncidentIdQuery] = React.useState(initialState.incidentIdQuery);
  const storeIncidentIdQuery = (value: string) => {
    setIncidentIdQuery(value);
  };

  const [championNameQuery, setChampionNameQuery] = React.useState(initialState.championNameQuery);
  const storeChampionNameQuery = (value: string) => {
    setChampionNameQuery(value);
  };

  return (
    <FieldAgentsProvider
      value={{
        tableIndex,
        storeTableIndex,
        showFieldOpsTableModal,
        storeShowFieldOpsTableModal,
        page,
        storePage,
        categorySearchQuery,
        storeCategorySearchQuery,
        statusSearchQuery,
        storeStatusSearchQuery,
        startDate,
        storeStartDate,
        endDate,
        storeEndDate,
        tableStartDateQuery,
        storeTableStartDateQuery,
        tableEndDateQuery,
        storeTableEndDateQuery,
        incidentIdQuery,
        storeIncidentIdQuery,
        championNameQuery,
        storeChampionNameQuery
      }}
    >
      {children}
    </FieldAgentsProvider>
  );
};

export default FieldAgentsContext;
