import React, { useState, createContext, useContext } from 'react';
const activeTab = 0;
const showInactiveContractTab = false;
const showStatusHistory = false;
const showVehicleHistory = false;
const showConfirmVehicleDisengage = false;
const showSuccessfulVehicleDisengage = false;
const initialIncidentObj = {
  id: '',
  description: '',
  incident_type_id: '',
  champion_id: '',
  field_agent_id: '',
  status: '',
  incident_address: '',
  created_at: '',
  updated_at: '',
  resolved_at: '',
  reporter_user_id: '',
  comments: '',
  incident_name: '',
  agent_first_name: '',
  agent_last_name: '',
  incident_id: ''
};
const initialState = {
  active: activeTab,
  //eslint-disable-next-line
  storeActiveTab: (value: number) => {},
  showInactiveContractDetail: showInactiveContractTab,
  //eslint-disable-next-line
  storeShowInactiveContractDetail: (value: boolean) => {},
  showStatusHistoryModal: showStatusHistory,
  //eslint-disable-next-line
  storeShowStatusHistoryModal: (value: boolean) => {},
  showVehicleHistoryModal: showVehicleHistory,
  //eslint-disable-next-line
  storeShowVehicleHistoryModal: (value: boolean) => {},
  showConfirmVehicleDisengageModal: showConfirmVehicleDisengage,
  //eslint-disable-next-line
  storeShowConfirmVehicleDisengageModal: (value: boolean) => {},
  showSuccessfulVehicleDisengageModal: showSuccessfulVehicleDisengage,
  //eslint-disable-next-line
  storeShowSuccessfulVehicleDisengageModal: (value: boolean) => {},
  incidentObj: initialIncidentObj,
  storeIncidentObj: (e: any) => {},
  showUpdateIncidentModal: false,
  setShowUpdateIncidentModal: (e: boolean) => {},
  showChangeModal: false,
  setShowChangeAgentModal: (e: boolean) => {},
  setIncidentId: (e: string) => {},
  incidentId: ''
};

const ChampionProfileContext = createContext(initialState);

export const useChampionContext = () => useContext(ChampionProfileContext);

export const ChampionProfileProvider = ChampionProfileContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const ChampionProfileProviderContainer: React.FC<Props> = ({ children }) => {
  const [active, setActive] = useState(initialState.active);
  const storeActiveTab = (value: number) => {
    setActive(value);
  };
  const [incidentObj, setIncidentObj] = useState<any>(initialState.incidentObj);
  const [showUpdateIncidentModal, setShowUpdateIncidentModal] = useState(false);
  const [showChangeModal, setShowChangeAgentModal] = useState(false);
  const [incidentId, setIncidentId] = useState('');
  const [showInactiveContractDetail, setShowInactiveContractDetail] = useState(initialState.showInactiveContractDetail);
  const storeShowInactiveContractDetail = (value: boolean) => {
    setShowInactiveContractDetail(value);
  };

  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(initialState.showStatusHistoryModal);
  const storeShowStatusHistoryModal = (value: boolean) => {
    setShowStatusHistoryModal(value);
  };

  const [showVehicleHistoryModal, setShowVehicleHistoryModal] = useState(initialState.showStatusHistoryModal);
  const storeShowVehicleHistoryModal = (value: boolean) => {
    setShowVehicleHistoryModal(value);
  };

  const [showConfirmVehicleDisengageModal, setShowConfirmVehicleDisengageModal] = useState(
    initialState.showConfirmVehicleDisengageModal
  );
  const storeShowConfirmVehicleDisengageModal = (value: boolean) => {
    setShowConfirmVehicleDisengageModal(value);
  };

  const [showSuccessfulVehicleDisengageModal, setShowSuccessfulVehicleDisengageModal] = useState(
    initialState.showSuccessfulVehicleDisengageModal
  );
  const storeShowSuccessfulVehicleDisengageModal = (value: boolean) => {
    setShowSuccessfulVehicleDisengageModal(value);
  };

  const storeIncidentObj = (obj: Record<string, any>) => {
    setIncidentObj(obj);
  };

  return (
    <ChampionProfileProvider
      value={{
        active,
        storeActiveTab,
        showInactiveContractDetail,
        storeShowInactiveContractDetail,
        showStatusHistoryModal,
        storeShowStatusHistoryModal,
        showVehicleHistoryModal,
        storeShowVehicleHistoryModal,
        showConfirmVehicleDisengageModal,
        storeShowConfirmVehicleDisengageModal,
        showSuccessfulVehicleDisengageModal,
        storeShowSuccessfulVehicleDisengageModal,
        incidentObj,
        storeIncidentObj,
        setShowUpdateIncidentModal,
        showUpdateIncidentModal,
        showChangeModal,
        setShowChangeAgentModal,
        setIncidentId,
        incidentId
      }}
    >
      {children}
    </ChampionProfileProvider>
  );
};

export default ChampionProfileContext;
