export const loginKey = '_DOMS-TK_';
export const userKey = '_DOMS-USER_';
export const ENCRYPTION_KEY = "doms-encry-tks";

export const queryKeys = {
  champion: 'current-champ',
  statusReason: 'status-reasons',
  championHistory: 'champion-history',
  dashboardChampion: 'dashboard-champion',
  vehicleDetails: 'champion-vehicle',
  vehicleMovementHistory: 'vehicle-history',
  avaliableVehicles: 'avaliable-vehicles',
  paymentHistory: 'payment-history',
  contract: 'contract-champ-with-id',
  inActiveContract: 'inActiveContract-champ-with-id',
  GUARANTORS_INFO: "guarantors-information", 
  PAYMENT_INFO: "payment-information",
  ALL_VEHICLES: 'get-all-vehicles-with-search',
  FLAGGED_CHAMPIONS:'flagged-champions',
  ASSIGN_TO_VO: 'assig-to-vo'
}
