import { loginKey } from "utils/constants";
import storage from "utils/storage";
import AuthenticatedApp from './Authenticated';
import UnauthenticatedApp from './UnAuthenticated';


const Pages = () => {
  const isUserAuthenticated = storage.get(loginKey);
  if (isUserAuthenticated) {
    return <AuthenticatedApp />
  }
  return <UnauthenticatedApp />
}

export default Pages
