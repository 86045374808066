import React, { useContext, useState, createContext } from 'react';
import { WelfareObject } from 'models/welfare';

const showPaymentHistory = false;  
const showStatusHistory = false;
const showWelfareTable = false;
const tabIndex = -1;
const pageNumber = 1;
const pageSize = 50;
const status = '';
const query = '';

const initialState = {
  showPaymentHistoryModal: showPaymentHistory,
  //eslint-disable-next-line
  storeShowPaymentHistoryModal: (value: boolean) => { },
  tableIndex: tabIndex,
  //eslint-disable-next-line
  storeTableIndex: (value: number) => { },
  showWelfareTableModal: { show: showWelfareTable, rowIndex: tabIndex },
  //eslint-disable-next-line
  storeShowWelfareTableModal: (value: boolean, index: number) => { },
  showStatusHistoryModal: showStatusHistory,
  //eslint-disable-next-line
  storeShowStatusHistoryModal: (value: boolean) => { },
  championInfo: {} as WelfareObject,
  //eslint-disable-next-line
  storeChampionInfo: (value: WelfareObject) => { },
  page: pageNumber,
  //eslint-disable-next-line
  storePage: (value: number) => { },
  championStatus: status,
  //eslint-disable-next-line
  storeChampionStatus: (value: string) => { },
  nameSearchQuery: query,
  //eslint-disable-next-line
  storeNameSearchQuery: (value: string) => { },
  championStatusName: query,
  //eslint-disable-next-line
  storeChampionStatusName: (value: string) => { },
  locationSearchQuery: query,
  //eslint-disable-next-line
  storeLocationSearchQuery: (value: string) => { },
  vehicleTypeSearchQuery: query,
  //eslint-disable-next-line
  storeVehicleTypeSearchQuery: (value: string) => { },
  startDate: query,
  //eslint-disable-next-line
  storeStartDate: (value: string) => { },
  endDate: query,
  //eslint-disable-next-line
  storeEndDate: (value: string) => {},
  tableStartDateQuery: query,
  //eslint-disable-next-line
  storeTableStartDateQuery: (value: string) => {},
  tableEndDateQuery: query,
  //eslint-disable-next-line
  storeTableEndDateQuery: (value: string) => {},
  size: pageSize,
  //eslint-disable-next-line
  churnReasonStartDate: query,
  //eslint-disable-next-line
  storeChurnReasonStartDate: (value: string) => { },
  churnReasonEndDate: query,
  //eslint-disable-next-line
  storeChurnReasonEndDate: (value: string) => { },
  incidentBreakdownStartDate: query,
  //eslint-disable-next-line
  storeIncidentBreakdownStartDate: (value: string) => { },
  incidentBreakdownEndDate: query,
  //eslint-disable-next-line
  storePageSize: (value: number) => { },
  storeIncidentBreakdownEndDate: (value: string) => { }
};

const WelfareContext = createContext(initialState);
export const useWelfareContext = () => useContext(WelfareContext);
export const WelfareProvider = WelfareContext.Provider;

type Props = {
  children?: React.ReactChild | React.ReactChild[];
};

export const WelfareProviderContainer: React.FC<Props> = ({ children }) => {
  const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(initialState.showPaymentHistoryModal);
  const storeShowPaymentHistoryModal = (value: boolean) => {
    setShowPaymentHistoryModal(value);
  };

  const [tableIndex, setTableIndex] = useState(initialState.tableIndex);
  const storeTableIndex = (value: number) => {
    setTableIndex(value);
  };

  const [showWelfareTableModal, setShowWelfareTableModal] = useState(initialState.showWelfareTableModal);
  const storeShowWelfareTableModal = (value: boolean, index: number) => {
    setShowWelfareTableModal({ show: value, rowIndex: index });
  };

  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(initialState.showStatusHistoryModal);
  const storeShowStatusHistoryModal = (value: boolean) => {
    setShowStatusHistoryModal(value);
  };

  const [championInfo, setChampionInfo] = useState(initialState.championInfo);
  const storeChampionInfo = (value: WelfareObject) => {
    setChampionInfo(value);
  };

  const [page, setPage] = React.useState(initialState.page);
  const storePage = (value: number) => {
    setPage(value);
  };

  const [size, setPageSize] = React.useState(initialState.size);
  const storePageSize = (value: number) => {
    setPageSize(value);
  };

  const [championStatus, setChampionStatus] = React.useState(initialState.championStatus);
  const storeChampionStatus = (value: string) => {
    setChampionStatus(value);
  };

  const [championStatusName, setChampionStatusName] = React.useState(initialState.championStatus);
  const storeChampionStatusName = (value: string) => {
    setChampionStatusName(value);
  };

  const [nameSearchQuery, setNameSearchQuery] = React.useState(initialState.nameSearchQuery);
  const storeNameSearchQuery = (value: string) => {
    setNameSearchQuery(value);
  };

  const [locationSearchQuery, setLocationSearchQuery] = React.useState(initialState.locationSearchQuery);
  const storeLocationSearchQuery = (value: string) => {
    setLocationSearchQuery(value);
  };

  const [vehicleTypeSearchQuery, setVehicleTypeSearchQuery] = React.useState(initialState.vehicleTypeSearchQuery);
  const storeVehicleTypeSearchQuery = (value: string) => {
    setVehicleTypeSearchQuery(value);
  };

  const [startDate, setStartDate] = React.useState(initialState.startDate);
  const storeStartDate = (value: string) => {
    setStartDate(value);
  };

  const [endDate, setEndDate] = React.useState(initialState.endDate);
  const storeEndDate = (value: string) => {
    setEndDate(value);
  };

  const [tableStartDateQuery, setTableStartDateQuery] = React.useState(initialState.tableStartDateQuery);
  const storeTableStartDateQuery = (value: string) => {
    setTableStartDateQuery(value);
  }; 

  const [tableEndDateQuery, setTableEndDateQuery] = React.useState(initialState.tableEndDateQuery);
  const storeTableEndDateQuery = (value: string) => {
    setTableEndDateQuery(value);
  };

  const [churnReasonStartDate, setChurnReasonStartDate] = React.useState(initialState.startDate);
  const storeChurnReasonStartDate = (value: string) => {
    setChurnReasonStartDate(value);
  };

  const [churnReasonEndDate, setChurnReasonEndDate] = React.useState(initialState.endDate);
  const storeChurnReasonEndDate = (value: string) => {
    setChurnReasonEndDate(value);
  };

  const [incidentBreakdownStartDate, setIncidentBreakdownStartDate] = React.useState(initialState.startDate);
  const storeIncidentBreakdownStartDate = (value: string) => {
    setIncidentBreakdownStartDate(value);
  };

  const [incidentBreakdownEndDate, setIncidentBreakdownEndDate] = React.useState(initialState.endDate);
  const storeIncidentBreakdownEndDate = (value: string) => {
    setIncidentBreakdownEndDate(value);
  };

  return (
    <WelfareProvider
      value={{
        showPaymentHistoryModal,
        storeShowPaymentHistoryModal,
        tableIndex,
        storeTableIndex,
        showWelfareTableModal,
        storeShowWelfareTableModal,
        showStatusHistoryModal,
        storeShowStatusHistoryModal,
        championInfo,
        storeChampionInfo,
        page,
        storePage,
        championStatus,
        storeChampionStatus,
        championStatusName,
        storeChampionStatusName,
        nameSearchQuery,
        storeNameSearchQuery,
        locationSearchQuery,
        storeLocationSearchQuery,
        vehicleTypeSearchQuery,
        storeVehicleTypeSearchQuery,
        startDate,
        storeStartDate,
        endDate,
        storeEndDate,
        tableStartDateQuery,
        storeTableStartDateQuery,
        tableEndDateQuery,
        storeTableEndDateQuery,
        churnReasonStartDate,
        storeChurnReasonStartDate,
        churnReasonEndDate,
        storeChurnReasonEndDate,
        incidentBreakdownStartDate,
        storeIncidentBreakdownStartDate,
        incidentBreakdownEndDate,
        storeIncidentBreakdownEndDate,
        size,
        storePageSize
      }}
    >
      {children}
    </WelfareProvider>
  );
};

export default WelfareContext;
