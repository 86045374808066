import React, { useContext, useState } from 'react';
import { FieldAgentsObject, IncidentInterface, FieldAgentIncidentInfoObject } from 'models/fieldOps';

const tabIndex = -1;
const pageNumber = 1;
const query = '';
const show = false;
const champs: any[] = [];
const id = '';

const initialState = {  
  tableIndex: tabIndex,
  //eslint-disable-next-line
  storeTableIndex: (value: number) => {},
  showFieldOpsTableModal: { show: show, rowIndex: tabIndex },
  //eslint-disable-next-line
  storeShowFieldOpsTableModal: (value: boolean, index: number) => {},
  page: pageNumber,
  //eslint-disable-next-line
  storePage: (value: number) => {},
  agentIdSearchQuery: query,
  //eslint-disable-next-line
  storeAgentIdSearchQuery: (value: string) => {},
  categorySearchQuery: query,
  //eslint-disable-next-line
  storeCategorySearchQuery: (value: string) => {},
  statusSearchQuery: query,
  //eslint-disable-next-line
  storeStatusSearchQuery: (value: string) => {},
  allIncidentsStartDate: query,
  //eslint-disable-next-line
  storeAllIncidentsStartDate: (value: string) => {},
  allIncidentsEndDate: query,
  //eslint-disable-next-line
  storeAllIncidentsEndDate: (value: string) => {},
  storeChampionsToAssign: (champ: any) => {},
  championsToAssign: champs,
  setChampionsToAssign: (e: any) => {},
  agentInfo: {} as FieldAgentsObject,
  // eslint-disable-next-line
  storeAgentInfo: (value: FieldAgentsObject) => {},
  agentId: id,
  //eslint-disable-next-line
  storeAgentId: (value: string) => {},
  tableStartDateQuery: query,
  //eslint-disable-next-line
  storeTableStartDateQuery: (value: string) => {},
  tableEndDateQuery: query,
  //eslint-disable-next-line
  storeTableEndDateQuery: (value: string) => {},
  incidentIdQuery: query,
  //eslint-disable-next-line
  storeIncidentIdQuery: (value: string) => {},
  championNameQuery: query,
  //eslint-disable-next-line
  storeChampionNameQuery: (value: string) => {},
  fieldOpsIncidentInfo: {} as FieldAgentIncidentInfoObject,
  storeFieldOpsIncidentInfo: (value: FieldAgentIncidentInfoObject) => {},
};

const FieldOpsContext = React.createContext(initialState);

export const FieldOpsProvider = FieldOpsContext.Provider;
export const useFieldOpsContext = () => useContext(FieldOpsContext);  
type Props = {
  children?: React.ReactChild | React.ReactChild[];
};



export const FieldOpsProviderContainer: React.FC<Props> = ({ children }) => {
  const [tableIndex, setTableIndex] = useState(initialState.tableIndex);
  const [championsToAssign, setChampionsToAssign] = useState<any[]>(initialState.championsToAssign);

  const storeChampionsToAssign = (champ: IncidentInterface) => {
    const checkChamp = championsToAssign.find((champion) => champion.incident_id === champ.incident_id);
    if (!checkChamp) {
      setChampionsToAssign((prev) => [...prev, champ]);
    } else {
      const updatedChamps = championsToAssign.filter((champion) => champion.incident_id !== champ.incident_id);
      setChampionsToAssign(updatedChamps);
    }
  };
  const storeTableIndex = (value: number) => {
    setTableIndex(value);
  };

  const [showFieldOpsTableModal, setShowFieldOpsTableModal] = useState(initialState.showFieldOpsTableModal);
  const storeShowFieldOpsTableModal = (value: boolean, index: number) => {
    setShowFieldOpsTableModal({ show: value, rowIndex: index });
  };

  const [page, setPage] = useState(initialState.page);
  const storePage = (value: number) => {
    setPage(value);
  };

  const [agentIdSearchQuery, setAgentIdSearchQuery] = React.useState(initialState.agentIdSearchQuery);
  const storeAgentIdSearchQuery = (value: string) => {
    setAgentIdSearchQuery(value);
  };

  const [categorySearchQuery, setCategorySearchQuery] = React.useState(initialState.categorySearchQuery);
  const storeCategorySearchQuery = (value: string) => {
    setCategorySearchQuery(value);
  };

  const [statusSearchQuery, setStatusSearchQuery] = React.useState(initialState.categorySearchQuery);
  const storeStatusSearchQuery = (value: string) => {
    setStatusSearchQuery(value);
  };

  const [allIncidentsStartDate, setAllIncidentsStartDate] = React.useState(initialState.allIncidentsStartDate);
  const storeAllIncidentsStartDate = (value: string) => {
    setAllIncidentsStartDate(value);
  };

  const [allIncidentsEndDate, setAllIncidentsEndDate] = React.useState(initialState.allIncidentsEndDate);
  const storeAllIncidentsEndDate = (value: string) => {
    setAllIncidentsEndDate(value);
  };

  const [agentInfo, setAgentInfo] = React.useState(initialState.agentInfo);
  const storeAgentInfo = (value: FieldAgentsObject) => {
    setAgentInfo(value);
  };

  const [agentId, setAgentId] = React.useState(initialState.agentId);
  const storeAgentId = (value: string) => {
    setAgentId(value);
  };

  const [tableStartDateQuery, setTableStartDateQuery] = React.useState(initialState.tableStartDateQuery);
  const storeTableStartDateQuery = (value: string) => {
    setTableStartDateQuery(value);
  };

  const [tableEndDateQuery, setTableEndDateQuery] = React.useState(initialState.tableEndDateQuery);
  const storeTableEndDateQuery = (value: string) => {
    setTableEndDateQuery(value);
  };

  const [incidentIdQuery, setIncidentIdQuery] = React.useState(initialState.incidentIdQuery);
  const storeIncidentIdQuery = (value: string) => {
    setIncidentIdQuery(value);
  };

  const [championNameQuery, setChampionNameQuery] = React.useState(initialState.championNameQuery);
  const storeChampionNameQuery = (value: string) => {
    setChampionNameQuery(value);
  };
  const [fieldOpsIncidentInfo, setFieldOpsIncidentInfo] = React.useState(initialState.fieldOpsIncidentInfo);
   const storeFieldOpsIncidentInfo = (value:FieldAgentIncidentInfoObject) => {
    setFieldOpsIncidentInfo(value)
   }


  return (
    <FieldOpsProvider
      value={{
        tableIndex,
        storeTableIndex,
        showFieldOpsTableModal,
        storeShowFieldOpsTableModal,
        page,
        storePage,
        agentIdSearchQuery,
        storeAgentIdSearchQuery,
        categorySearchQuery,
        storeCategorySearchQuery,
        statusSearchQuery,
        storeStatusSearchQuery,
        allIncidentsStartDate,
        storeAllIncidentsStartDate,
        allIncidentsEndDate,
        storeAllIncidentsEndDate,
        storeChampionsToAssign,
        championsToAssign,
        setChampionsToAssign,
        agentInfo,
        storeAgentInfo,
        agentId,
        storeAgentId,
        tableStartDateQuery,
        storeTableStartDateQuery,
        tableEndDateQuery,
        storeTableEndDateQuery,
        incidentIdQuery,
        storeIncidentIdQuery,
        championNameQuery,
        storeChampionNameQuery,
        fieldOpsIncidentInfo,
        storeFieldOpsIncidentInfo
      }}
    >
      {children}
    </FieldOpsProvider>
  );
};

export default FieldOpsContext;
