import { PROTECTED_PATHS } from 'routes/constants';

export interface NavListInterface {
  path: string;
  name: string;
}

export const NavList: NavListInterface[] = [
  {
    path: PROTECTED_PATHS.CONTACT_CENTER,
    name: 'Contact Center'
  },
  {
    path: PROTECTED_PATHS.DASHBOARD,
    name: 'Dashboard'
  },
  {
    path: PROTECTED_PATHS.WELFARE,
    name: 'Welfare'
  },
  {
    path: PROTECTED_PATHS.FIELD_OPS,
    name: 'Field Ops'
  },
  {
    path: PROTECTED_PATHS.ADMINISTRATION,
    name: 'Administration'
  },
  {
    path: PROTECTED_PATHS.FLAGGED_CHAMPIONS,
    name: 'Flagged Champions'
  },
  // {
  //   path: PROTECTED_PATHS.RE_VERIFICATION,
  //   name: 'Reverification'
  // },
  {
    path: PROTECTED_PATHS.NOTIFICATION,
    name: 'Notifications'
  }
  // {
  //   path: PROTECTED_PATHS.APPLICATION_HUB,
  //   name: 'Application Hub'
  // }
];
