import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'styles/theme';
import Pages from 'pages';
import { ReactQueryDevtools } from 'react-query/devtools';
import { WelfareProviderContainer } from 'contexts/welfare';
import { ChampionProfileProviderContainer } from 'contexts/championProfile';
import { FieldOpsProviderContainer } from 'contexts/fieldOps';
import { FieldAgentsProviderContainer } from 'contexts/fieldAgent';

import 'react-datepicker/dist/react-datepicker.css';
import { ContractProviderContainer } from 'contexts/contractsContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 600_000,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

const RenderDevTool = () => {
  if (process.env.NODE_ENV === 'development') {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }
  return null;
};

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ChampionProfileProviderContainer>
          <WelfareProviderContainer>
            <FieldOpsProviderContainer>
              <FieldAgentsProviderContainer>
                <ContractProviderContainer>
                  <Pages />
                </ContractProviderContainer>
              </FieldAgentsProviderContainer>
            </FieldOpsProviderContainer>
          </WelfareProviderContainer>
        </ChampionProfileProviderContainer>
        <RenderDevTool />
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
