import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/buttonStyles";
import { InputStyles as Input } from "./components/inputStyles";
import { SelectStyles as Select } from "./components/selectStyles";

// custom themes in chakra UI
// https://chakra-ui.com/docs/theming/customize-theme
//https://www.easyreact.com/articles/chakra-ui-customisations

const domsTheme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "TT Regular",
    body: "TT Regular",
    secondary: "TT Regular",
    primary: "TT Regular",
  },
  colors: {
    primary: "#FCDD18",
    secondary: "#2FA07224",
    highlight: "#00C9A7",
    warning: "#FFC75F",
    danger: "#C34A36",
    text: "#454545",
    light: "#334DBA24",
    // to generate color scheme    
    //https://github.com/chakra-ui/chakra-ui/discussions/2846
    // https://smart-swatch.netlify.app/#2FA072
    lime:
    {
      50: '#ffeddf',
      100: '#fccdb6',
      200: '#f5ac8a',
      300: '#ef8c5d',
      400: '#e96c2f',
      500: '#d05216',
      600: '#a23f10',
      700: '#742d0a',
      800: '#471902',
      900: '#1e0600',
    },
    black:
    {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    }
  },
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ['18px', '24px',],
      lineHeight: '110%',
      letterSpacing: '-2%',
    },
    h2: {
      fontSize: ['36px', '48px'],
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    h5: {
      fontSize: ['18px', '24px'],
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    p: {
      fontSize: ['14px', '18px',],
      fontWeight: '300',
      marginBottom:"10px",
      lineHeight: '110%',
    },
    label:{
      textAlign: 'left',
      letterSpacing: '0px',
      color: '#3E3E3E',
      opacity: 0.5,
      marginBottom:'10px',
      fontSize:'12px'
    }
  },
  components: {
    Button, // Has to match to the name of the component
    Input,
    Select
  },
});

export default domsTheme;
