import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { ENCRYPTION_KEY } from './constants';

export const encrypt_key = (token: any) => {
  let encryptedToken = aes.encrypt(token, ENCRYPTION_KEY).toString();
  return encryptedToken;
};

export const decrypt_key = (token: any) => {
  let decryptedToken = aes.decrypt(token, ENCRYPTION_KEY);
  return decryptedToken.toString(enc);
};

export const encrypt_userobj = (user: any) => {
  let encryptedUser = aes.encrypt(JSON.stringify(user), ENCRYPTION_KEY).toString();

  return encryptedUser;
};

export const decrypt_user0bj = (user: any) => {
  let decryptedUser = aes.decrypt(user, ENCRYPTION_KEY);
  return JSON.parse(decryptedUser.toString(enc));
};
